<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

import simplebar from "simplebar-vue";
import {mapGetters} from "vuex";



export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  computed: {
    ...mapGetters([
      'hasRole'
    ])
  },
  components: {
    /* eslint-disable vue/no-unused-components */
    simplebar,
    Layout,
    AgentInfo: () => import('@/components/lazy/dashboard/AgentInfo'),
    SystemInfo: () => import('@/components/lazy/dashboard/SystemInfo'),
    TransactionStats: () => import('@/components/lazy/dashboard/TransactionStats'),
    Cashflow: () => import('@/components/lazy/dashboard/Cashflow'),
    Products: () => import('@/components/lazy/dashboard/Products'),
    AccountBalances: () => import('@/components/lazy/dashboard/AccountBalances'),
    SevDeskOverview: () => import('@/components/lazy/dashboard/SevDeskOverview'),
    YearlyRevenue: () => import('@/components/lazy/dashboard/AccountBalances'),
  },
  data() {
    return {

    };
  },
  validations: {

  },
  methods: {

  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18">
        Dashboard
      </h4>
      <div class="page-title-right bv-d-xs-down-none"><ol class="breadcrumb m-0">
        <li class="breadcrumb-item">
          <router-link to="/">
            <span class="text-danger text-uppercase">
              <i class="fad fa-clipboard-user" />
              CFTools Internal
            </span>
          </router-link>
        </li>
      </ol>
      </div>
    </div>
    <AgentInfo/>

    <SystemInfo/>

    <template v-if="hasRole('financial_data')">
      <hr>
      <TransactionStats/>
      <Cashflow/>
      <div class="row">
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
          <AccountBalances/>
          <SevDeskOverview/>
          <!-- <YearlyRevenue/> -->
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
          <Products/>
        </div>
      </div>
    </template>
  </Layout>
</template>